import $ from 'jquery';

window.$ = $;
window.jQuery = $;

export default $;

// file this one under stupid things you have to do when libraries have a
// dependency on jquery being present in the global namespace
// https://stackoverflow.com/questions/34338411/how-to-import-jquery-using-es6-syntax
